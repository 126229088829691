
import { getSliceComponentProps } from '@prismicio/vue/components';
import advantagesPushAdapter from '@/slices/AdvantagesPush/adapter';

export default {
  name: 'AdvantagesPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      sliceData: null,
    };
  },
  async fetch() {
    const data = await advantagesPushAdapter(this.slice, {
      prismic: this.$prismic,
      enhancedLinkSerializer: this.$enhancedLinkSerializer,
      i18n: this.$i18n,
    });
    this.sliceData = data;
  },
};
