import { stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { routerLinks } from '@/utils/fetch-links';

async function adapter({ primary }, { prismic, i18n, enhancedLinkSerializer }) {
  const lang = i18n.localeProperties.iso;
  const module = await prismic.api.getByID(primary?.advantage_module?.id, {
    fetchLinks: routerLinks,
    lang,
  });
  const moduleData = module?.data;

  if (!moduleData) {
    return null;
  }

  const isFigureListItems = moduleData.is_figure_list_items;

  let needfetch = false;

  const listItems = moduleData.advantage_entries.map((item) => {
    const linkData = item.advantage_entry_link?.data;

    // Check if link is using market or product to force module fetch
    if (linkData && (linkData.market || linkData.product)) {
      needfetch = true;
    }

    return {
      text: item.advantage_entry_title,
      ...(isFigureListItems
        ? { figure: item.advantage_entry_figure }
        : {
            icon: imageAdapter(item.avantage_entry_icon),
            link: enhancedLinkSerializer(item.advantage_entry_link),
          }),
    };
  });

  // Fetch module again to have all the needed data to generate links
  if (needfetch) {
    const push = await prismic.api.getByID(primary.advantage_module.id, {
      fetchLinks: routerLinks,
      lang,
    });

    if (push && push?.data?.advantage_entries) {
      push.data.advantage_entries.forEach((entry, index) => {
        if (entry.advantage_entry_link) {
          listItems[index].link = enhancedLinkSerializer(
            entry.advantage_entry_link
          );
        }
      });
    }
  }

  return {
    title: stripRichTextWrapperTag(moduleData.advantage_title),
    listItems,
    isFigureListItems,
  };
}

export default adapter;
